import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { parsePhoneNumber } from "libphonenumber-js";

export const currencyMask = createNumberMask({
  prefix: "₺",
  allowDecimal: true,
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ".",
  decimalSymbol: ",",
  allowNegative: false,
});

export const validateUrl = (urlString) => {
  var urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + // validate protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // validate fragment locator
  return !!urlPattern.test(urlString);
};

export function isNumeric(str) {
  if (typeof str != "string") return false;
  return !isNaN(str) && !isNaN(parseFloat(str));
}

export function checkUserAgeUnder18(birth_date) {
  if (birth_date === null || birth_date === undefined) return false;
  const birthDate = birth_date.split("-");
  const birthYear = parseInt(birthDate[0]);
  const birthMonth = parseInt(birthDate[1]);
  const birthDay = parseInt(birthDate[2]);
  const today = new Date();
  const todayYear = today.getFullYear();
  const todayMonth = today.getMonth() + 1;
  const todayDay = today.getDate();
  let age = todayYear - birthYear;
  if (
    todayMonth < birthMonth ||
    (todayMonth == birthMonth && todayDay < birthDay)
  ) {
    age = age - 1;
  }
  if (age < 18) {
    return true;
  }
  return false;
}

export function formatPhoneInput(phone) {
  try {
    const number = parsePhoneNumber(phone);
    return number.formatInternational();
  } catch (e) {
    return phone;
  }
}

export function formatPrice(price) {
  if (price === null || price === undefined) return null;
  if (typeof price === "string") {
    price = parseFloat(price);
  }
  return (
    price.toLocaleString("tr-TR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }) + "₺"
  );
}
